import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-3e78ecdc"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["tabindex", "type", "disabled", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_icon = _resolveComponent("hit-icon");
    const _component_hit_spinner = _resolveComponent("hit-spinner");
    return _withDirectives((_openBlock(), _createElementBlock("button", {
        tabindex: _ctx.ignoreOnTab ? -1 : 0,
        type: _ctx.type,
        class: _normalizeClass(["inline-grid hit-button justify-center grid-flow-col items-center border rounded border-transparent line-height-initial", _ctx.buttonClasses]),
        disabled: _ctx.disabled || _ctx.showSpinner,
        title: _ctx.tooltip,
        style: _normalizeStyle(_ctx.customColorStyles),
        onClick: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
        _renderSlot(_ctx.$slots, "prefixIcon", {}, () => [
            (_ctx.prefixIcon && !_ctx.showSpinner)
                ? (_openBlock(), _createBlock(_component_hit_icon, {
                    key: 0,
                    class: "button-icon",
                    size: _ctx.iconSize,
                    icon: _ctx.prefixIcon,
                    color: _ctx.iconColor,
                    "icon-color-style": _ctx.iconColorStyle
                }, null, 8, ["size", "icon", "color", "icon-color-style"]))
                : (_ctx.showSpinner)
                    ? (_openBlock(), _createBlock(_component_hit_spinner, {
                        key: 1,
                        size: "sm"
                    }))
                    : _createCommentVNode("", true)
        ], true),
        (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.labelClasses)
            }, _toDisplayString(_ctx.label), 3))
            : _createCommentVNode("", true),
        (_ctx.suffixIcon)
            ? (_openBlock(), _createBlock(_component_hit_icon, {
                key: 1,
                class: "button-icon",
                size: _ctx.iconSize,
                icon: _ctx.suffixIcon,
                color: _ctx.iconColor
            }, null, 8, ["size", "icon", "color"]))
            : _createCommentVNode("", true)
    ], 14, _hoisted_1)), [
        [_vShow, !_ctx.hidden]
    ]);
}
