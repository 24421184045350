<template>
  <hit-app-input-rich-text
    :value="container.data[attribute]"
    :validation-state="validate ? container.validationState[attribute] : null"
    :disabled="container.attributes[attribute].readOnly || disabled"
    @change="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {globals} from 'hit-online-web-ui/src/globals';
import {useConfigurationStore, useUserProfileStore} from '../../../store';
import axios from 'axios';
import {useI18n} from 'vue-i18n';
import {DataService} from '../../../api';
import {HitAppInputRichText} from '../../hit';

export default {
  name: 'HitContainerInputRichText',
  components: {
    HitAppInputRichText,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
    pastedImagesIdentifier: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      refHitInputRichText: null,
      refFileInput: null,
      fileModalOpen: false,
      selectedFile: null,
      selectedFileDesignation: '',
      fileBrowserVisible: false,
      standardTexts: [],
      baseUrl: `${globals.$appContext.storageUrl}documents/`,
    };
  },
  computed: {
    pastedImagesIdentifierStripped() {
      if (typeof this.pastedImagesIdentifier !== 'string') {
        return null;
      }
      return this.pastedImagesIdentifier.replace(/[^a-zA-Z0-9-_]/g, '');
    },
    theme() {
      return useConfigurationStore().configuration['user.theme'];
    },
  },
  mounted() {
    this.loadStandardTexts();
  },
  methods: {
    async handleUpdate(htmlValue) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlValue, 'text/html');
      const imgElements = doc.querySelectorAll('img');
      let dataModified = false;
      for (const img of imgElements) {
        if (!img.src.includes(globals.$appContext.storageUrl)) {
          let imgContent, imgFilename;
          let base64RegEx = /^data:image\/(png|jpg|jpeg|gif);base64,/;
          if (base64RegEx.test(img.src)) {
            let match = img.src.match(base64RegEx);
            let imgType = match[1];
            let base64String = img.src.substring(match[0].length);
            imgFilename = this.getPastedImgName() + '.' + imgType;
            imgContent = this.base64ToBlob(base64String, 'image/' + imgType);
          } else if (/^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(img.src)) {
            let imgType = img.src.substring(img.src.lastIndexOf('.') + 1);
            imgFilename = this.getPastedImgName() + '.' + imgType;
            imgContent = await this.fetchFile(img.src);
          } else {
            console.error('invalid img format, removing img');
            img.remove();
            dataModified = true;
            continue;
          }
          try {
            let url = await this.uploadFile(
              imgContent,
              imgFilename,
              this.getPastedImgName()
            );
            img.src = url + '/data';
          } catch (e) {
            img.remove();
          }
          dataModified = true;
        }
      }
      if (dataModified) {
        const cursorPosBeforeInserting = this?.refHitInputRichText
          ?.lastCursorPos;
        this.refHitInputRichText.tiptapEditor.commands.setContent(
          doc.documentElement.outerHTML
        );
        if (typeof cursorPosBeforeInserting === 'number') {
          this.refHitInputRichText.tiptapEditor
            .chain()
            .focus()
            .setTextSelection(cursorPosBeforeInserting + 1)
            .run();
        }
      }
    },
    setCursorAfterInsertingImage() {
      if (typeof this?.refHitInputRichText?.lastCursorPos === 'number') {
        this.refHitInputRichText.tiptapEditor
          .chain()
          .focus()
          .setTextSelection(this.refHitInputRichText.lastCursorPos + 1)
          .run();
      }
    },
    async fetchFile(url) {
      const responseFetch = await fetch(url, {method: 'GET'});
      return await responseFetch.blob();
    },
    base64ToBlob(base64Data, contentType) {
      const binaryString = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }
      return new Blob([uint8Array], {type: contentType});
    },
    async uploadAndInsertImage() {
      const fileToBlob = async (file) =>
        new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type});
      const blob = await fileToBlob(this.selectedFile);
      let url = await this.uploadFile(
        blob,
        this.selectedFile.name,
        this.selectedFileDesignation
      );
      this.insertImage(url);
      this.setCursorAfterInsertingImage();
    },
    insertImage(url) {
      let newContent = this.refHitInputRichText.tiptapEditor.getHTML();
      newContent += "<img src='" + url + "/data'/>";
      this.refHitInputRichText.tiptapEditor.commands.setContent(newContent);
      this.fileModalOpen = false;
    },
    async uploadFile(content, fileName, designation, comment = null) {
      const fileData = {
        active: true,
        designation: designation,
        comment: comment,
        authorId: useUserProfileStore().staffId,
      };
      try {
        const headers = {
          Authorization: `Bearer ${globals.$keycloak.token}`,
          'Content-Type': 'application/json',
        };
        const response = await axios.post(this.baseUrl, fileData, {headers});

        const formData = new FormData();
        formData.append('file', content, fileName);

        headers['Content-Type'] = 'multipart/form-data';
        await axios.post(`${this.baseUrl}${response.data.id}/data`, formData, {
          headers,
        });
        return `${this.baseUrl}${response.data.id}`;
      } catch (error) {
        console.error(error);
      }
    },
    getPastedImgName() {
      let now = new Date();
      let year = now.getFullYear().toString().slice(-2);
      let month = ('0' + (now.getMonth() + 1)).slice(-2);
      let day = ('0' + now.getDate()).slice(-2);
      let hours = ('0' + now.getHours()).slice(-2);
      let minutes = ('0' + now.getMinutes()).slice(-2);
      let seconds = ('0' + now.getSeconds()).slice(-2);

      return (
        (this.pastedImagesIdentifierStripped || 'memo') +
        '_' +
        year +
        month +
        day +
        '_' +
        hours +
        minutes +
        seconds
      );
    },
    handleExistingItemSelected(item) {
      let url = this.baseUrl + item.id;
      this.insertImage(url);
      this.setCursorAfterInsertingImage();
    },
    loadStandardTexts() {
      const localeSuffix = useUserProfileStore().localeSnakeCase;
      let fields = [
        'designation_' + localeSuffix,
        'description_' + localeSuffix,
        'id',
      ];
      DataService.read('standard_text', {
        attributes: fields.join(','),
      }).then((response) => {
        this.standardTexts = response.data.map((item) => {
          return {
            id: item.id,
            description: item['description_' + localeSuffix],
            designation: item['designation_' + localeSuffix],
          };
        });
      });
    },
  },
};
</script>
