import {defineStore} from 'pinia';
import {DataService, useNotificationsStore} from '@hit/base';
import {translate} from '@hit/base/src/plugins/i18n/i18n';
import {globals} from 'hit-online-web-ui/src/globals';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export const useUserProfileStore = defineStore('userProfile', {
  state: () => ({
    uuid: '',
    hitOnlineStaffId: '',
    username: '',
    locale: 'en-GB',
    roles: [],
  }),
  getters: {
    /**
     * Returns the keycloak ID of the connected user (loaded during mounted of App)
     */
    keycloakId(state) {
      return state.uuid;
    },

    /**
     * Returns the staffId of the connected user (loaded during mounting of App)
     */
    staffId(state) {
      return state.hitOnlineStaffId;
    },

    /**
     * Returns the selected locale of the user in snake case format
     */
    localeSnakeCase(state) {
      return state.locale.replace('-', '_').toLowerCase();
    },
  },
  actions: {
    /**
     * Method executed when the application is loaded to only load the staffId once
     */
    async initUserProfileStore() {
      return Promise.all([this.loadStaffId(), this.setKeycloakId()]);
    },

    /**
     * Sets the staffID of the user
     */
    async loadStaffId() {
      const res = await DataService.read('staff', {
        attributes: 'id,staff_qualification(qualification_id)',
        filters: {
          user_id: `eq.${globals.$keycloak.idTokenParsed.sub}`,
        },
      });
      if (res && res.data && res.data.length > 0) {
        this.hitOnlineStaffId = res.data[0].id;
        this.roles = res.data[0].staff_qualification.map(
          (item) => item.qualification_id
        );
      }
    },

    /**
     * Sets the keycloak id
     * @returns {Promise<unknown>}
     */
    async setKeycloakId() {
      return new Promise((resolve, reject) => {
        try {
          this.uuid = globals.$keycloak.idTokenParsed.sub;
          resolve(this.uuid);
        } catch (error) {
          reject(error);
        }
      });
    },

    setRoles(roles) {
      this.roles = roles;
    },
  },
});
