<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-project-part')"
    route="project_part"
    auto-complete-id="project-part"
    :table-attributes="
      filterIdColumn && filterId
        ? tableAttributes
        : {...additionalProjectAttributes, ...tableAttributes}
    "
    :default-sort="{property: 'no', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    visible
  >
    <template #item.status.display="{formData}">
      {{ getProjectPartStatusStringFromKey(formData.status) }}
    </template>
  </hit-entity-browser>
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
export default {
  name: 'HitProjectPartBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t, te} = useI18n();
    return {t, te};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '1.5fr',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.project.project-part-designation'),
            maxWidth: '2.5fr',
          },
        }),
        active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'active',
            header: this.t('hit-app.common.active'),
            maxWidth: '4rem',
          },
        }),
        status: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            propertyName: 'status',
            header: this.t('hit-app.common.status'),
            maxWidth: '2.5fr',
          },
        }),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '2fr',
          },
        }),
      },
      additionalProjectAttributes: {
        projectNo: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'project',
              column: 'no',
            },
            transform: (project) => project.no,
            tableProperties: {
              header: this.t('hit-app.common.project-no'),
              maxWidth: '2fr',
              mainSearch: true,
            },
          }
        ),
        projectDesignation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'project(designation)',
          transform: (project) => project.designation,
          tableProperties: {
            header: this.t('hit-app.common.project-designation'),
            maxWidth: '2fr',
          },
        }),
        projectId: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'project(id)',
          transform: (project) => project.id,
        }),
      },
    };
  },
  methods: {
    getProjectPartStatusStringFromKey(key) {
      if (!key || !(typeof key === 'string')) {
        return '';
      }
      let i18nKey =
        'hit-app.hit-project-part-status.' +
        key.toLowerCase().replaceAll('_', '-');
      if (this.te(i18nKey)) {
        return this.t(i18nKey);
      } else {
        return key;
      }
    },
  },
};
</script>
