<template>
  <OnClickOutside
    @trigger="
      () => {
        $emit('clickOutside');
        hideDropdown();
      }
    "
  >
    <div class="hit-dropdown relative w-full">
      <hit-button
        v-if="!$slots.replaceButton"
        :label="hideLabel ? null : label"
        :prefix-icon="icon"
        :suffix-icon="hideDropdownIcon || !label ? null : 'expand-bottom'"
        :color="buttonColor"
        :icon-color-style="iconColorStyle"
        :white-background="whiteBackground"
        :remove-outline="true"
        :remove-padding="removePadding"
        :hide-border="hideBorder"
        :gap-size="buttonGapSize"
        :icon-size="iconSize"
        :prevent-click="true"
        :class="{
          'h-6': removePadding,
        }"
        :disabled="disabled"
        @click="toggleDropdown($event)"
      >
        <template #prefixIcon>
          <!-- @slot Prefix of the button -->
          <slot name="prefix" />
        </template>
      </hit-button>
      <slot
        name="replaceButton"
        :toggle-dropdown="toggleDropdown"
      />
      <div
        v-if="dropdownVisible || forceDropDownVisible"
        class="origin-top-right right-0 fixed mt-2 w-auto rounded-md shadow-lg whitespace-no-wrap z-50"
        :class="{
          absolute: responsiveBreakpointSm || !fullWidthUnderSm,
          'fixed w-full p-2': !responsiveBreakpointSm && fullWidthUnderSm,
        }"
      >
        <div
          class="rounded-md bg-panel ring-1 ring-black ring-opacity-5 text-panel-light py-1 dropdown-content"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <!-- @slot Content of the dropdown menu -->
          <slot />
        </div>
      </div>
      <slot name="suffix" />
    </div>
  </OnClickOutside>
</template>

<script>
import {HitButton} from '../button';
import {OnClickOutside} from '@vueuse/components';
import HitBreakpointsMixin from '@hit/components/src/mixins/breakpoints/HitBreakpointsMixin';

export default {
  name: 'HitDropdown',
  components: {
    OnClickOutside,
    HitButton,
  },
  mixins: [HitBreakpointsMixin],
  provide() {
    return {
      hideDropdown: this.hideDropdown,
    };
  },
  props: {
    /**
     * Label of the dropdown button to display
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Icon of the dropdown button
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Specifies the colors to use for the button
     */
    buttonColor: String,
    /**
     * Forces the background to be white
     */
    whiteBackground: Boolean,
    /**
     * Hide the dropdown button label
     */
    hideLabel: Boolean,
    /**
     * Hide the dropdown button icon
     */
    hideDropdownIcon: Boolean,
    /**
     * Hide the dropdown button border
     */
    hideBorder: Boolean,
    /**
     * Remove the dropdown button padding
     */
    removePadding: Boolean,
    /**
     * Size of gap in the dropdown button
     */
    buttonGapSize: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['none', 'small', 'normal'].indexOf(value) !== -1;
      },
    },
    /**
     * Size of the icon in the dropdown button
     */
    iconSize: {
      type: String,
      default: 'base',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['2xs', 'xs', 'sm', 'base', 'md', 'lg', 'xl'].indexOf(value) !== -1
        );
      },
    },
    /**
     * Can be used to force the visible state of the dropdown from the parent element
     */
    forceDropDownVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidthUnderSm: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconColorStyle: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown(evt) {
      this.dropdownVisible = !this.dropdownVisible;
      /**
       * When the dropdown has been clicked
       */
      this.$emit('click');
      evt.stopImmediatePropagation();
    },
    hideDropdown() {
      this.dropdownVisible = false;
      this.$emit('outsideClick');
    },
  },
};
</script>
