// @ts-nocheck
import { useConfigurationStore } from '@hit/base';
import { useI18n } from 'vue-i18n';
export const ATTRIBUTE_TYPES = {
    STRING: 0,
    BOOLEAN: 1,
    ARRAY: 2,
    DATE: 3,
    DECIMAL: 4,
    MULTI_LANGUAGE: 5,
    FOREIGN_ID: 6,
    DATETIME: 7,
    ML_WITH_FALLBACK: 8,
    COLOUR: 9,
    INT: 10,
    NUMBER: 11,
    NOT_FETCHED: 12,
    COMPUTED_COLUMN: 13,
    ENUMERATION: 14,
    TAGS: 15,
    AGGREGATE_FUNCTION: 16,
    DATETIME_SECONDS: 17,
    FK_JOINED_OBJECT: 18,
    JOINED_STRING_COLUMN: 20,
    JOINED_COMPUTED_COLUMN: 21,
};
export class HitContainerAttribute {
    dataType;
    column = null;
    creationValue = null;
    validations = {};
    validationGroup = null;
    transform = null;
    decimalPrecision;
    decimalDefaultValue;
    readOnly; // used to declare readonly values in the db, will prevent writing this value into db
    formLocation;
    tableProperties = {};
    forcedType;
    formItemName; // is used instead of the column attribute to store the data in the form object
    fkToUpdate;
    constructor(dataType, { column = null, creationValue = null, validations = {}, validationGroup = null, transform = null, decimalPrecision = 2, decimalDefaultValue = 0, readOnly = false, formLocation = null, tableProperties = {}, forcedType = null, formItemName = null, fkToUpdate = null, } = {}) {
        this.dataType = dataType;
        this.creationValue = creationValue;
        this.validations = validations || {};
        this.validationGroup = validationGroup || null;
        this.transform = transform;
        this.decimalPrecision = decimalPrecision;
        this.decimalDefaultValue = decimalDefaultValue;
        this.readOnly = readOnly;
        this.formLocation = formLocation;
        this.tableProperties = tableProperties;
        this.forcedType = forcedType;
        this.formItemName = formItemName;
        this.fkToUpdate = fkToUpdate;
        if (dataType === ATTRIBUTE_TYPES.TAGS) {
            this.column = {
                table: column?.table ?? 'tag',
                column: column?.column ?? 'designation',
                selectColumns: 'id, designation, colour',
            };
        }
        else {
            this.column = column;
        }
    }
    getInitialValue() {
        const configStore = useConfigurationStore();
        if (this.dataType === ATTRIBUTE_TYPES.STRING) {
            return this.creationValue ? this.creationValue.toString() : undefined;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.BOOLEAN) {
            return this.creationValue ? Boolean(this.creationValue) : false;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.DECIMAL) {
            if (typeof this.decimalDefaultValue === 'string') {
                return parseFloat(this.decimalDefaultValue).toFixed(this.decimalPrecision);
            }
            else if (typeof this.decimalDefaultValue === 'number') {
                return this.decimalDefaultValue.toFixed(this.decimalPrecision);
            }
        }
        else if (this.dataType === ATTRIBUTE_TYPES.ARRAY) {
            return this.creationValue ? this.creationValue : [];
        }
        else if (this.dataType === ATTRIBUTE_TYPES.DATE ||
            this.dataType === ATTRIBUTE_TYPES.DATETIME ||
            this.dataType === ATTRIBUTE_TYPES.DATETIME_SECONDS) {
            let currentDate = new Date();
            let year = currentDate.getFullYear();
            let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month starts from 0
            let day = String(currentDate.getDate()).padStart(2, '0');
            const today = `${year}-${month}-${day}`;
            return this.creationValue ? this.creationValue : today;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.FOREIGN_ID) {
            return this.creationValue ? this.creationValue : null;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.MULTI_LANGUAGE) {
            let result = {};
            configStore.companyLanguagesSnakeCase.forEach((locale) => (result[locale] = undefined));
            return result;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.COLOUR) {
            return this.creationValue ? this.creationValue : '2358a8';
        }
        else if (this.dataType === ATTRIBUTE_TYPES.INT) {
            return this.creationValue ? this.creationValue : null;
        }
        else if (this.dataType === ATTRIBUTE_TYPES.NUMBER) {
            return this.creationValue ? this.creationValue : null;
        }
    }
}
export function generateMultiLanguageAttributes(prefix, type, options = {}) {
    const generatedAttributes = {};
    const configStore = useConfigurationStore();
    const t = useI18n().t;
    const locales = configStore.companyLanguagesSnakeCase;
    let firstLocale = true;
    locales.forEach((locale) => {
        locale = locale.replace(/-/g, '_').toLowerCase();
        const localizedConfig = replaceLocale(options, locale);
        const adaptedTableProperties = { ...options.tableProperties };
        adaptedTableProperties.header = `${adaptedTableProperties.header} (${t(`hit-base.common.${locale}`)})`;
        if (firstLocale && options.tableProperties?.mainSearch) {
            adaptedTableProperties.mainSearch = true;
            firstLocale = false;
        }
        else {
            adaptedTableProperties.mainSearch = false;
        }
        generatedAttributes[`${prefix}_${locale}`] = new HitContainerAttribute(type, {
            column: localizedConfig.column,
            validations: options.validations,
            transform: localizedConfig.transform,
            decimalPrecision: localizedConfig.decimalPrecision,
            decimalDefaultValue: localizedConfig.decimalDefaultValue,
            tableProperties: locales.length > 1 ? adaptedTableProperties : options.tableProperties,
        });
    });
    return generatedAttributes;
}
/**
 * Replaces all LOCALE placeholder in the given object by the passed localeVariable
 * @param object - object where we want to replace the placeholders
 * @param localeVariable - used locale
 */
function replaceLocale(object, localeVariable) {
    const replacedObject = {};
    for (const [key, value] of Object.entries(object)) {
        if (typeof value === 'object' && value !== null) {
            replacedObject[key] = replaceLocale(value, localeVariable);
        }
        else if (typeof value === 'string') {
            replacedObject[key] = value.replace(/LOCALE/g, localeVariable);
        }
        else if (typeof value === 'function') {
            const functionSource = value.toString();
            const replacedFunctionSource = functionSource.replace(/LOCALE/g, localeVariable);
            replacedObject[key] = eval(`(${replacedFunctionSource})`);
        }
        else {
            replacedObject[key] = value;
        }
    }
    return replacedObject;
}
