<template>
  <hit-input-checkbox
    v-if="valueType === 'boolean'"
    :value="value"
    :disabled="disabled"
    :name="name"
    :validation-state="validationState"
    :inline-input="inlineInput"
    @change="onChange"
    @input="onInput"
  />
  <hit-input
    v-else-if="valueType === 'date'"
    type="date"
    :value="value"
    :disabled="disabled"
    :name="name"
    :instant-focus="instantFocus"
    :validation-state="validationState"
    :inline-input="inlineInput"
    @change="onChange"
    @input="onInput"
  />
  <hit-input
    v-else-if="valueType === 'datetime'"
    type="datetime-local"
    :value="value"
    :disabled="disabled"
    :name="name"
    :instant-focus="instantFocus"
    :validation-state="validationState"
    :inline-input="inlineInput"
    @change="onChange"
    @input="onInput"
  />
  <hit-input
    v-else-if="valueType === 'datetime-seconds'"
    type="datetime-local"
    :value="value"
    :disabled="disabled"
    :name="name"
    :instant-focus="instantFocus"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :show-seconds="true"
    @change="onChange"
    @input="onInput"
  />
  <hit-input-text-area
    v-else-if="valueType === 'text'"
    :value="value"
    :disabled="disabled"
    :name="name"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :rows="rows"
    :instant-focus="instantFocus"
    @change="onChange"
    @input="onInput"
  />
  <hit-input-colour-picker
    v-else-if="valueType === 'colour'"
    :name="name"
    :value="value"
    :disabled="disabled"
    :inline-input="inlineInput"
    @change="onChange"
    @input="onInput"
  />
  <hit-input
    v-else-if="valueType === 'decimal' || valueType === 'int'"
    :value="value"
    :disabled="disabled"
    :name="name"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :instant-focus="instantFocus"
    text-alignment="text-right"
    :select-entire-content-on-focus="true"
    @change="onChange"
    @input="onInput"
  />
  <hit-input
    v-else
    :value="value"
    :disabled="disabled"
    :name="name"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :instant-focus="instantFocus"
    @change="onChange"
    @input="onInput"
  />
</template>

<script>
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import HitInput from './HitInput';
import HitInputTextArea from './HitInputTextArea';
import HitInputCheckbox from '../input/HitInputCheckbox.vue';
import HitInputColourPicker from '../input/HitInputColourPicker.vue';

export default {
  name: 'HitInputGeneric',
  components: {
    HitInput,
    HitInputTextArea,
    HitInputCheckbox,
    HitInputColourPicker,
  },
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    /**
     * Value of the input
     */
    value: undefined,
    /**
     * Type of the value
     */
    valueType: {
      type: String,
      default: null,
    },
    /**
     * Number of rows for the text area
     */
    rows: {
      type: Number,
      default: null,
    },
    /** Set to true to focus the input when the page is loaded **/
    instantFocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput($event) {
      /**
       * When the input value has changed due to a user input
       */
      this.$emit('input', $event);
      try {
        $event.stopImmediatePropagation();
      } catch {
        // not necessary if fails
      }
    },
    onChange($event) {
      /**
       * When the input value has changed
       */
      this.$emit('change', $event);
      try {
        $event.stopImmediatePropagation();
      } catch {
        // not necessary if fails
      }
    },
  },
};
</script>
