<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-tax-code')"
    route="tax_code"
    auto-complete-id="tax_code"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
//dont shorten import
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
export default {
  name: 'HitTaxCodeBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '1fr',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.designation'),
            mainSearch: true,
            maxWidth: '1fr',
          },
        }),
        percentage: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
          tableProperties: {
            header: this.t('hit-base.common.percentage'),
            precision: 2,
            maxWidth: '1fr',
          },
        }),
        journal_type: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
          transform: (item) =>
            this.t(`hit-app.hit-journal-type.${item.toLowerCase()}`),
          tableProperties: {
            header: this.t('hit-app.journal.journal-type'),
            maxWidth: '1fr',
          },
        }),
      },
    };
  },
};
</script>
